<template>
  <div>
    <flixDashboard>
      <template v-slot:content>
        <div class="flix-container">
          <div class="flix-row flix-form-group">
            <div class="flix-col-md-6 flix-col-md-flix-offset-3">
              <div class="flix-form-group">
                <chat :entryID="$route.params.entryID"></chat>
              </div>
            </div>
          </div>
        </div>
      </template>
    </flixDashboard>
  </div>
</template>

<script>
export default {
  components: {
    chat () { return import('@/components/chat/interessentChat') }
  }
}
</script>
